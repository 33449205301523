<template>
  <div class="wrapper" v-infinite-scroll="loadData" infinite-scroll-distance="10">
    <div v-for="(e, i) in list" :key="i" class="item">
      <div class="top">
        <div class="time">{{ getTime(e)?.date }}
          <span>
            {{ getTime(e)?.dateRange }}
          </span>
        </div>
        <div class="beijingTime" v-if="!isCN()">（北京时间：{{ getBeijingTime(e) }}）</div>
        <div class="status" :style="statusStyleNew[e.status]">{{ getStaus(e) }}</div>
      </div>
      <div class="neck">
        <div class="teacher"><img :src="e.teacherPic" v-if="e.teacherPic" class="teacherPic" /> {{ e.teacherName
        }}老师</div>
        <div>
          <!-- <div :class="['operation', getOperationIsDisabled(e) && 'operationDisabled']" @click="operation(e)"
            v-if="showOperation(e)">
            {{ getOperationStr(e) }}
          </div> -->
          <div v-if="e.status === 1 && e.hasCourseware" class="operation" @click="downloadContent(e)">下载课件</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  transferCNTimeToLocal, showBjTime,
  isCN,
} from '@/utils/timezone';
import { connectState } from '../../../../utils/wyUtil';
import { recordStatus, statusStyleNew } from '../../config';

export default {
  name: 'OralLessonList',
  props: {
    statusList: Array,
    commenNow: Function,
    cancelBook: Function,
    activeTab: Number,

  },
  components: {
  },
  mounted() {
    this.getOralRecords({ page: 1 });
  },
  data() {
    return {
      page: 0,
      statusStyleNew,
      busy: true,
      total: 0,
      limit: 10,
    };
  },
  watch: {
    activeTab() {
      this.getCurTabList();
    },
  },

  methods: {
    getCurTabList() {
      this.total = 0;
      this.page = 0;
      this.getOralRecords({ page: 1 });
    },
    cancelCallBack() {
      this.getCurTabList();
    },
    loadData() {
      if (this.busy) return;
      const haveMore = this.page < Math.ceil(this.total / this.limit);
      if (haveMore) {
        this.getOralRecords({ page: this.page + 1 });
      }
    },
    showOperation(row) {
      return row.status === 1 || row.status === 0;
    },
    getOperationIsDisabled(row) {
      return row.status === 0 && this.checkDisable(row.beginTime);
    },
    checkDisable(beginTime) {
      const now = new Date().getTime();
      const begin = new Date(beginTime).getTime();
      return begin < now;
    },
    operation(row) {
      if (row.status === 1) {
        return this.commenNow && this.commenNow(row);
      }
      if (row.status === 0 && !this.getOperationIsDisabled(row)) {
        return this.cancelBook && this.cancelBook(row);
      }
    },
    downloadContent(row) {
      row.coursewareUrl.split(',').map((item) => {
        fetch(item).then((res) => res.blob()).then((blob) => { // 将链接地址字符内容转变成blob地址
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `课件${Date.now()}`; // 下载文件的名字
          document.body.appendChild(a);
          a.click();
        });
      });
    },
    setPage(total) {
      this.page = ++this.page;
    },
    isCN,
    getTime(row) {
      const begin = transferCNTimeToLocal(row.beginTime, 'YYYY-MM-DD HH:mm');
      const date = transferCNTimeToLocal(row.beginTime, 'YYYY-MM-DD');
      const dateRange = `
            ${transferCNTimeToLocal(row.beginTime, 'HH:mm')
}-${transferCNTimeToLocal(row.endTime, 'HH:mm')
}`;

      return {
        date,
        dateRange,
      };
    },
    getBeijingTime(row) {
      return `${showBjTime(row.beginTime, 'YYYY-MM-DD HH:mm')}-${showBjTime(row.endTime, 'HH:mm')}`;
    },
    getStaus(row) {
      return recordStatus[row.status];
    },
    async getOralRecords({ page }) {
      this.busy = true;
      try {
        await this.$store.dispatch('oral/userOralRecords', {
          statusType: this.statusList,
          curPage: page,
          limit: this.limit,
        });
        this.page = ++this.page;
        this.total = this.oralRecords?.total || 0;
      } finally {
        this.busy = false;
      }
    },
    getOperationStr(row) {
      if (row.status === 1) {
        return '评价';
      }
      if (row.status === 0) {
        return '取消';
      }
      return '';
    },
  },
  computed: {
    ...connectState('oral', ['oralRecords', 'oralRecordsList']),
    list() {
      return (this.oralRecordsList || []);
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.item {
  padding: 24px 16px 16px 24px;
  background: #FFFFFF;
  border-radius: 16px;
  margin-bottom: 16px;

  .operationDisabled {
    cursor: not-allowed !important;
    color: #C0C4CC !important;
    border-color: #C0C4CC !important;
  }

  .time {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #8E95A3;

    span {
      margin-left: 10px;
      font-size: 22px;
      color: rgba(51, 54, 67, 1);
    }
  }

  .beijingTime {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #8E95A3;
    margin-left: 8px;
  }
}

.top {
  display: flex;
  align-items: center;

  .status {
    margin-left: 8px;

    height: 24px;
    line-height: 24px;
    border-radius: 8px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    padding: 0 8px;
  }
}

.neck {
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .teacher {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #8E95A3;
  }

  .teacherPic {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .operation {
    height: 28px;
    border-radius: 11px;
    border: 1px solid rgba(51, 119, 255, 0.3);

    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #3377FF;
    line-height: 26px;
    padding: 0 14px;
    cursor: pointer;
    text-align: center;

    &:last-child {
      margin-top: 10px;
    }
  }
}
</style>
